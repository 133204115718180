@import "./configs/color.css";

@media only screen and (max-width: 768px) {
  .row {
    margin-left: 0;
    margin-right: 0;
  }
}

/**
 * Custom form elements
 *    - Checkbox
 *    - Radios
 *    - Switch
 *    - Rounded inputs
 */
.c-checkbox,
.c-radio {
  margin-right: 0;
}
.c-checkbox *,
.c-radio * {
  cursor: pointer;
}
.c-checkbox input,
.c-radio input {
  opacity: 0;
  position: absolute;
  margin-left: 0 !important;
}
.c-checkbox span,
.c-radio span {
  position: relative;
  display: inline-block;
  vertical-align: sub;
  width: 14px;
  height: 14px;
  background-color: #FFFFFF;
    border-radius: 2px;
  color: #B9BDC1;
  border: 1px solid $primary;
  text-align: center;
}
.c-checkbox span:before,
.c-radio span:before {
  margin-left: 1px;
}
.c-checkbox:hover span,
.c-radio:hover span {
  border-color: #000000;
}
.form-inline .c-checkbox span, .form-inline
.c-radio span {
  margin-left: 0;
}
.c-checkbox.c-checkbox-rounded span, .c-checkbox.c-radio-rounded span,
.c-radio.c-checkbox-rounded span,
.c-radio.c-radio-rounded span {
  border-radius: 500px;
}

/* override for radio */
.c-radio span {
  border-radius: 500px;
}

/* the icon */
.c-checkbox span:before,
.c-radio span:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  text-align: center !important;
  font-size: 11px;
  line-height: 14px;
  vertical-align: middle;
    margin-left: 0;
}

/* Checked state */
.c-checkbox,
.c-radio {
  /* override for radio */
  /* Disable state */
  /* override for radio */
}
.c-checkbox input[type=checkbox]:checked + span:before,
.c-checkbox input[type=radio]:checked + span:before,
.c-radio input[type=checkbox]:checked + span:before,
.c-radio input[type=radio]:checked + span:before {
  color: #FFFFFF;
  background-color: #000000;
  opacity: 1;
    width: 12px;
    height: 12px;
  transition: color .3s ease-out;
}
.lectura input[type=checkbox]:checked + span:before,
.lectura input[type=radio]:checked + span:before,
.lectura input[type=checkbox]:checked + span:before,
.lectura input[type=radio]:checked + span:before {
  color: $danger;
  background-color: $danger;
  opacity: 1;
  transition: color .3s ease-out;
  cursor: initial;
}
.c-checkbox input[type=checkbox]:checked + span,
.c-checkbox input[type=radio]:checked + span,
.c-radio input[type=checkbox]:checked + span,
.c-radio input[type=radio]:checked + span {
  border-color: #000000;
  background-color: #000000;
}
.lectura input[type=checkbox]:checked + span,
.lectura input[type=radio]:checked + span,
.lectura input[type=checkbox]:checked + span,
.lectura input[type=radio]:checked + span {
  border-color: #fff !important;
  background-color: #eff3f6 !important;
  cursor: initial;
}
.lectura{
  cursor: initial !important;
}
.lec{
    border: 0px solid #eff3f6 !important;
}
.c-checkbox input[type=radio]:checked + span,
.c-radio input[type=radio]:checked + span {
  background-color: #fff;
}
.c-checkbox input[type=radio]:checked + span:before,
.c-radio input[type=radio]:checked + span:before {
  color: $danger;
}
.c-checkbox input[type=checkbox]:disabled + span,
.c-checkbox input[type=radio]:disabled + span,
.c-radio input[type=checkbox]:disabled + span,
.c-radio input[type=radio]:disabled + span {
  border-color: #ddd !important;
  background-color: #ddd !important;
}
.c-checkbox input[type=radio]:disabled + span,
.c-radio input[type=radio]:disabled + span {
  background-color: #fff !important;
}
.c-checkbox input[type=radio]:disabled + span:before,
.c-radio input[type=radio]:disabled + span:before {
  color: #ddd;
}

.c-radio.c-radio-nofont {
  /* override for radio */
  /* Disable state */
  /* override for radio */
}
.c-radio.c-radio-nofont span:before {
  content: "";
  width: 10px;
  height: 10px;
  top: 50%;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
  border-radius: 500px;
}
.c-radio.c-radio-nofont input[type=radio]:checked + span:before {
  color: #fff;
  opacity: 1;
  transition: color .3s ease-out;
}
.c-radio.c-radio-nofont input[type=radio]:checked + span {
  border-color: $danger;
  background-color: $danger;
}
.c-radio.c-radio-nofont input[type=radio]:checked + span {
  background-color: #fff;
}
.c-radio.c-radio-nofont input[type=radio]:checked + span:before {
  background-color: $danger;
}
.c-radio.c-radio-nofont input[type=radio]:disabled + span {
  border-color: #ddd !important;
  background-color: #ddd !important;
}
.c-radio.c-radio-nofont input[type=radio]:disabled + span {
  background-color: #fff !important;
}
.c-radio.c-radio-nofont input[type=radio]:disabled + span:before {
  background-color: #ddd;
}

.show-it-table-header {
    background: #FFFFFF 0 0 no-repeat padding-box;
    /*min-height: 30px !important;*/
    letter-spacing: 0;
    width: 100%;
    font-size: 12px !important;
    /*border-style: none;*/
    text-align: left;
    text-transform: capitalize !important;
    /*text-anchor: end;*/
    font-family: Poppins-Regular, sans-serif !important;
    font-weight: 100;
    opacity: 0.6 !important;
    color: #000000;
    margin: 0;
    min-width: 100%;
    direction: ltr;
    border-radius: 10px 10px 0 0 !important;
    border-bottom: 3px solid #9b9b9b !important;;
}

.customs-header2 {
    border-radius: 10px 10px 0 0 !important;
}

.customs-headers {
    border-radius: 10px 10px 0 0 !important;
}

.display-none {
    display: none !important;
}

.read-checkbox {
  position: relative;
  display: inline-block;
  vertical-align: sub;
  width: 14px;
  height: 14px;
  background-color: #FFFFFF;
    border-radius: 2px;
  color: #FFF;
  border: 1px solid $primary;
  text-align: center;
}
.read-checkbox i {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  text-align: center !important;
  font-size: 11px;
  line-height: 14px;
  vertical-align: middle;
    margin-left: 0;
}


.read-checkbox i.checked {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  text-align: center !important;
  font-size: 11px;
  line-height: 14px;
  color: #707070;
  background-color: transparent;
  opacity: 1;
  width: 12px;
  height: 12px;
  transition: color .3s ease-out;
}
