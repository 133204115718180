/* Section button style */
@import "./configs/color.css";

.btn {
    min-width: 7rem;
}

.button-01 {
    /*padding: 0 auto !important;*/
    background: $primary 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 2px $primary;
    border: 1px solid $primary;
    border-radius: 3px;
    letter-spacing: 0;
    color: #FFFFFF !important;
    opacity: 1;
}

.button-02 {
    padding: 0 auto !important;
    background: #B9BDC1 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 2px #B9BDC199;
    border: 1px solid #B9BDC1;
    border-radius: 3px;
    opacity: 1;
    letter-spacing: 0;
    color: #FFFFFF !important;;
}

.button-03 {
    padding: 0 auto !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 2px $primary;
    border: 1px solid $primary;
    border-radius: 3px;
    letter-spacing: 0;
    color: $dark !important;;
    opacity: 1;
}

.button-04 {
    padding: 0 auto !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 2px $danger;
    border: 1px solid $danger;
    border-radius: 3px;
    letter-spacing: 0;
    color: $danger !important;;
    opacity: 1;
}

.button-05 {
    padding: 0 auto !important;
    background: #F1F1F1 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 2px #55555599;
    border: 1px solid #7E8A97;
    border-radius: 3px;
    opacity: 1;
    text-align: center;
    justify-content: center;
    font: normal normal bold 16px/19px Poppins;
    letter-spacing: 0;
    color: #555555 !important;;
}

.button-01:hover {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 2px $primary;
    border: 1px solid $primary;
    border-radius: 3px;
    color: $dark !important;
    opacity: 1;
    /* padding: 2px 15px; */
}

.button-02:hover {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 2px #B9BDC199;
    border: 1px solid #B9BDC1;
    border-radius: 3px;
    letter-spacing: 0;
    color: $dark !important;
    opacity: 1;
}

.button-03:hover {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 2px $primary;
    border: 1px solid $dark;
    border-radius: 3px;
    color: $primary !important;
    opacity: 1;
}

.button-04:hover {
    letter-spacing: 0;
    color: $dark !important;
    opacity: 1
}

.button-05:hover {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 2px $primary;
    border: 1px solid $dark;
    border-radius: 3px;
    color: $primary !important;
    opacity: 1;
}

.react-switch-bg {
    border-radius: 20px !important;
    opacity: 1 !important;
}

.search-input {
    background: transparent !important;
    /*border: solid 2px $opacity !important;*/
    border-radius: 20px;
    display: inline-block;
    outline: none;
    border: 2px solid $opacity !important;
    min-width: 12%;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .375rem .375rem 2.35rem;
    text-align: left;
    font: normal normal normal 13px/17px Poppins;
    opacity: 1;
}

/*input .search-input:focus {*/
/*    !*border: none !important;*!*/
/*    border: 1px solid red !important;*/
/*}*/

.search-input:focus {
    border: 1.5px solid #6D6D6D !important;
    min-width: 12%;
}

label {
    font: normal normal 500 14px/17px Poppins;
    letter-spacing: 0;
    color: #7b7b7b;
    opacity: 1;
    display: inline-block;
    margin-bottom: .5rem;
}

.text-12 {
    font-size: .80rem;
}

.text-12-2 {
    font-size: .80rem;
    color: black;
    text-align: justify !important;
    /*font-family: Poppins-SemiBold, sans-serif;*/
}

.text-11 {
    font-size: 11px;
    color: black;
}

.poppins-semi-bold {
    font-family: Poppins-SemiBold, sans-serif;
}

.react-responsive-modal-overlay {
    background: rgba(0, 0, 0, 0.4) !important;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
}

.header-page {
    border: 0;
    width: 100%;
    text-align: left;
    font: normal normal 600 28px/46px Poppins-SemiBold;
    letter-spacing: 0;
    color: $dark;
    text-transform: capitalize;
    opacity: 1;
    margin-left: 1.2rem;
    margin-top: 0;
    margin-bottom: 0.9rem;
    border-bottom: solid 4px $danger;
}

.min-h-100 {
    min-height: 100% !important;
}

.detail-title {
    font: normal normal bolder 13px/17px Poppins;
    letter-spacing: 0;
    color: #555555;
    opacity: 0.8;
    padding: 0;
    margin: 0;
}

.detail-description {
    text-align: left;
    font: normal normal bold 18px/21px Poppins;
    letter-spacing: 0;
    text-transform: capitalize;
    color: #555555;
    opacity: 1;
}

.border-card-page {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 4px 7px 11px #2049694D !important;
    border-radius: 5px !important;
    opacity: 1 !important;
}

/* Style td-headers */
.custom-headers {
    min-height: 30px !important;
    font: normal normal medium 8px/5px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    box-shadow: none !important;
}

/* icons of standard action */
.custom-container-icon {
    /*width: 55%;*/
    min-width: 18px;
    max-width: 1.04rem;
}

.custom-search-icon {
    width: 18px;
    height: 18px;
    margin-right: -30px;
    display: inline;
}

/* background-color navbar*/
.bg-gray {
    background-color: $menu;
    box-shadow: none !important;
}


.main-custom-navbar {
    height: 4.75rem !important;
}

.custom-mt {
    margin-top: 2.80rem !important;
}

/* text style section */
.txt-danger {
    font: normal normal medium 14px/22px Poppins;
    letter-spacing: 0px;
    color: $danger;
    opacity: 1;
}

/* Signup Form style */
.container-logo-login {
    margin: 10px auto;
}

.container-logo-register {
    margin: 10px auto;
}

.container-logo-register .logo-form {
    width: 15% !important;
    display: block !important;
    margin: auto !important;
}

.logo-form {
    width: 25%;
}

.logo-form-sing-in {
    width: 65%;
    margin: 2px auto;
    display: block;
}

.color-link {
    color: $primary;
}

.paragraph {
    /*font: normal normal 600 14px/17px Poppins-SemiBold;*/
    font: normal normal 400 12px/16px Poppins-SemiBold !important;
    /*font-family: Poppins-Regular, sans-serif !Important;*/
    font-weight: normal !important;
    letter-spacing: 0;
    opacity: 1;
}

.text-login {
    font-size: 12px;
    text-align: center;
    font-family: Poppins, sans-serif;
    letter-spacing: 0;
    color: #414141;
    opacity: 1;
}

.border-ima {
    background: #F1F1F1 0% 0% no-repeat padding-box;
    border: 1.5px solid #B3BDCC;
    border-radius: 5px;
    opacity: 1;
}

.box-image img {
    width: 100%;
    height: 100%;
    display: block;
}

.skip {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    text-align: center;
    border-bottom: 0.5px solid $primary;
    cursor: pointer;
    padding: 0 1.5rem;
}

.skip:hover {
    color: $secondary;
    border-bottom: .5px solid $secondary;
}

.back-icon {
    position: absolute;
    z-index: 1;
    width: 15px;
    opacity: .5;
    cursor: pointer;
}

.back-icon:hover {
    opacity: .6;
}


/*  ReactCodeInput Styles */
.styles_react-code-input__CRulA input {
    border-right: none !important;
    border-left: none !important;
    border-top: none !important;
    margin-right: 10px !important;
    border-bottom: 2px solid #C7C7C7 !important;
    background-color: white !important;
    border-radius: 0 !important;
}

.styles_react-code-input-container__tpiK {
    position: relative;
    width: 295px !important;
    display: inline-block !important;
    border-right: none;
    border-left: none;
    border-top: none;
}

.styles_react-code-input__CRulA > input:last-child {
    border-right: none;
    border-left: none;
    border-top: none;
}

.is-invalid .styles_react-code-input__CRulA input {
    border-right: none !important;
    border-left: none !important;
    border-top: none !important;
    margin-right: 10px !important;
    border-bottom: 2px solid #c4183c !important;
    background-color: white !important;
    border-radius: 0 !important;
}

.is-invalid .styles_react-code-input__CRulA input:first-child {
    margin-left: 10px;
}

.question1 {
    font: normal normal 600 14px/19px Poppins;
    letter-spacing: 0;
    color: #000000;
    opacity: 1;
}

.question2 {
    font: normal normal 600 20px/19px Poppins;
    letter-spacing: 0;
    color: #7E8A97;
    opacity: 1;
}

.styles_react-code-input__CRulA > input:focus {
    border-right: none;
    border-left: none;
    border-top: none;
    border-bottom: 2px solid #000000;
    caret-color: $primary;
}

.active-circle {
    background: $primary 0% 0% no-repeat padding-box !important;
    transition: width 0.3s ease;
}

.circle {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid $primary;
    opacity: 1;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
    transition: width 0.3s ease;
    transition-duration: 300ms;
    transform: translateX(-50%) scale(1);
}

.circle:first-child {
    margin-left: 5px;
    transition: width 0.3s ease;
}

.box-shadow2 {
    box-shadow: 2px 3px 10px #33313B80;
}

.filter-job {
    height: 22rem;
    overflow-y: scroll;
    overflow-x: hidden;
}

.filter-job::-webkit-scrollbar {
    width: 5px; /* Tamaño del scroll en vertical */
    /*height: 8px;    !* Tamaño del scroll en horizontal *!*/
    /*display: none;  !* Ocultar scroll *!*/
}

.filter-job::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
}

.table-scroll-1 {
    height: 24rem;
    display: block;
    overflow-y: scroll;
    overflow-x: scroll;
    margin-bottom: 0;
    padding: 0 10px;
}

.table-scroll-1::-webkit-scrollbar {
    width: 5px; /* Tamaño del scroll en vertical */
    /*height: 8px;    !* Tamaño del scroll en horizontal *!*/
    /*display: none;  !* Ocultar scroll *!*/
}

.table-scroll-1::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
}

.table-scroll-2::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
}

.table-scroll-2 {
    height: 18rem;
    display: block;
    overflow-y: scroll;
    overflow-x: scroll;
    margin-bottom: 0;
    padding: 0 10px;
}

.table-scroll-2::-webkit-scrollbar {
    width: 5px; /* Tamaño del scroll en vertical */
    height: 8px;
}

.table-scroll-3 {
    height: 30rem !important;
    display: block;
    overflow-y: scroll;
    overflow-x: scroll;
    margin-bottom: 0;
    padding: 0 10px;
}

.table-scroll-3::-webkit-scrollbar {
    width: 5px; /* Tamaño del scroll en vertical */
    /*height: 8px;    !* Tamaño del scroll en horizontal *!*/
    /*display: none;  !* Ocultar scroll *!*/
}

.table-scroll-3::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
}

.table-scroll-4 {
    height: 25.8rem !important;
    display: block;
    overflow-y: scroll;
    overflow-x: scroll;
    margin-bottom: 0;
    padding: 0 10px;
}

.table-scroll-4::-webkit-scrollbar {
    width: 5px; /* Tamaño del scroll en vertical */
    /*height: 8px;    !* Tamaño del scroll en horizontal *!*/
    /*display: none;  !* Ocultar scroll *!*/
}

.table-scroll-4::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
}

.table-scroll-details {
    height: 29rem !important;
    display: block;
    overflow-y: scroll;
    overflow-x: scroll;
    margin-bottom: 0;
    padding: 0 10px;
}

.table-scroll-details::-webkit-scrollbar {
    width: 5px; /* Tamaño del scroll en vertical */
    /*height: 8px;    !* Tamaño del scroll en horizontal *!*/
    /*display: none;  !* Ocultar scroll *!*/
}

.table-scroll-details::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
}

.sticky-top-header {
    background: white;
    position: sticky;
    top: 0; /* Don't forget this, required for the stickiness */
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}


.custom_nav_ul {
    list-style: none !important;
    background-color: #C7C7C7;
    padding: 0 !important;
    margin: 0 !important;
}

.active-2 {
    background-color: #dc3545;
    color: white !important;
}

.name-small {
    margin-top: .5px;
    font-size: 0.65rem;
    font-weight: normal;
    color: #000000 !important;
}

.square-color {
    width: 22px;
    height: 3.5px;
}

.checked-icon {
    color: black;
    font-size: 0.86rem;
    cursor: pointer;
}

thead {
    color: #7b7b7b !important;
    font-family: Poppins, sans-serif;
    font-weight: lighter !important;
}

th {
    font-weight: 400 !important;
    text-transform: capitalize !important;
    font-size: .79rem !important;
}

tbody {
    color: #7b7b7b !important;
}

.css-dvua67-singleValue {
    color: #7b7b7b !important;
}

.new-black {
    color: #444444 !important;
    font-family: Poppins-SemiBold, sans-serif;
}

.label-small {
    color: #7b7b7b !important;
    font-size: 0.72rem;
    font-weight: bold;
    letter-spacing: 0;
}

.label-semi-bold {
    color: #707070 !important;
    font-family: Poppins-SemiBold, sans-serif;
    font-size: 0.98rem;
    letter-spacing: 0;
}

.label-color-gray {
    color: #707070 !important;
    font-family: Poppins-SemiBold, sans-serif;
    letter-spacing: 0;
}

.border-card-20 {
    border-radius: 20px !important;
}

.message-code {
    font: normal normal 600 12px/19px Poppins;
    letter-spacing: 0;
    color: #000000;
    opacity: 1;
}

.broker-register {
    font: normal normal 600 1.15rem Poppins-SemiBold;
    letter-spacing: 0;
    color: #5a6169;
    opacity: 1;
}

.button-eyes {
    margin-left: -2.2rem;
    background-color: transparent;
    border: solid 1px transparent !important;
}

.line-y-0 {
    line-height: 0.6;
}

.container-cell-icon {
    width: 25px;
    height: 20px;
}

.min-max-card {
    min-width: 13rem;
    max-width: 43rem;
}

.text-small-chart {
    font-size: 0.62rem;
}

.start-small {
    font-size: 16px;
}

div .undefined .react-stars {
    margin-left: auto !important;
    margin-right: auto !important;
}

.link-blue {
    color: #0c8da5 !important;
    font-weight: bold;
    text-decoration-line: underline !important
}


.table-scroll-auto {
    flex: 1;
    /* min-height: 25.5rem !important; */
    height: auto;
    display: block;
    overflow-y: auto;
    overflow-x: auto;
    margin-bottom: 0;
    padding: 0 10px;
}
/* .table-scroll-sponcer-list {
    max-height: 10rem !important;
    display: block;
    overflow-y: scroll;
    overflow-x: scroll;
    margin-bottom: 0;
    padding: 0 10px;
} */

.table-scroll-auto::-webkit-scrollbar {
    width: 5px; /* Tamaño del scroll en vertical */
    /*height: 8px;    !* Tamaño del scroll en horizontal *!*/
    /*display: none;  !* Ocultar scroll *!*/
}

.table-scroll-auto::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
}

.table-footer {
    height: 50px;
    padding: 10px 0 !important;
}
.table-footer th {
    font-size: 15px !important;
    color: #7b7b7b !important;
    font-weight: bold !important;
}

.text-total {
    color: #000000 !important;
    position: absolute;
    font-family: Poppins-SemiBold, sans-serif;
    font-size: 0.68rem !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
