@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.vendor-register-container {
    height: 100vh;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    align-items: center;
}
.vendor-register-wrapper {
    min-height: 60%;
    width: 100%;
    display   : flex !important;
    flex-direction: column !important;
    align-items: center !important;
    padding: 20px 0 !important;
    margin: auto;
}
.vendor-register-card {
    flex: 1 !important;
    border-radius     : 1rem !important;
    background-color  : #A11500 !important;
    opacity           : 1;
    border            : 0;
    -webkit-box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    box-shadow        : 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    padding           : 1.5em !important;
    position          : relative;
    display           : flex;
}

.vendor-register-card-body {
    flex            : 1;
    border-radius   : 1rem !important;
    background-color: #EAE9E9;
    min-width       : 100%;
    display         : flex;
}

.vendor-register-title-page-container {
    padding        : 15px !important;
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
    height         : 100%;
}

.vendor-register-titles-container {
    display           : flex;
    flex-direction    : column;
    /* justify-content: center; */
    align-items       : center;
    height            : 100%;
}

.vendor-register-title {
    font-size  : 2rem;
    color      : #282828;
    font-weight: 800;
}

.vendor-register-subtitle {
    font-size  : 1.5rem;
    color      : #A11500;
    font-weight: 600;
}

.vendor-register-logo-container {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    width          : 100%;
}

.vendor-register-logo-container img {
    width        : 60%;
    margin-bottom: 2rem;
}
.vendor-register-back-button-container {
    width: 50px;
    margin-right: 10px;
}
.vendor-register-back-button {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.vendor-register-back-button img {
    width: 15px;
}
.vendor-register-form-container {
    display         : flex;
    flex-direction  : column;
    height          : 100%;
    background-color: #FFF;
    padding: 15px !important;
    border-radius   : 1rem !important;
}
.vendor-register-info-text {
    font-size: 0.75rem;
    color: #282828;
    font-weight: 500;
    margin-bottom: 0;
}
.vendor-register-form-title {
    font-size  : 1.5rem;
    color      : #282828;
    font-weight: 600;
    margin-bottom: 0;
}

.vendor-register-button {
    background-color: #8A8A8A !important;
    border-color: #505A71 !important;
    border-width: 2px !important;
    border-radius: 5px !important;
    color: #FFFFFF !important;
}
.vendor-register-button:hover {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 2px solid #7E8A97;
    border-radius: 5px !important;
    color: #414141 !important;
    opacity: 1;
}

.vendor-register-upload-text {
    font-size: 1rem;
    color: #282828;
    font-weight: 500;
    margin-bottom: 0;
}
.vendor-register-label-title {
    font-size: 1rem;
    color: #282828;
    font-weight: 600;
    margin-bottom: 0;
}
.vendor-register-label-subtitle {
    font-size: 0.8rem;
    color: #282828;
    font-weight: 500;
    margin-bottom: 0;
}
.vendor-register-link {
    font-size: 0.8rem;
    font-weight: 500;
    margin-bottom: 0;
    cursor: pointer;
    text-decoration: underline;
}

/* ONLY XL SCREEN */
@media screen and (min-width: 1400px) {
    .vendor-register-wrapper {
        min-height: 70%;
    }
    .vendor-register-title {
        font-size: 4rem;
    }

    .vendor-register-subtitle {
        font-size: 3rem;
    }
    .vendor-register-info-text {
        font-size: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .vendor-register-card {
        padding: 1em !important;
    }

    .vendor-register-title-page-container {
        height: auto;
    }
}
@media screen and (max-width: 576px) {
    .vendor-register-form-title {
        flex:  1;
        font-size: 1.2rem;
    }
}