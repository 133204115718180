.show-it-btn {
    background-color: #fff;
    margin: 10px 0;
    padding: 8px;
    /* box-shadow: 1px 2px 2px rgb(32 73 105 / 30%) !important; */
    border-radius: 5px !important;
}

.show-it-btn:hover {
    background-color: #eeeeee;
}

.show-it-btn.selected {
    color: #FFF;
    background-color: #AA1711 !important;
}